import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import { BreakpointsProvider } from 'react-with-breakpoints'
import theme from './styles/theme'
require('typeface-source-sans-pro')

const GlobalStyle = createGlobalStyle`
  html, a, button, body, div, span, applet, object, input, select, option, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: Source Sans Pro, Sans-Serif;
    font-weight: 400;
    vertical-align: baseline;
    box-sizing: border-box;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    font-size: 18px;
    background: #fafafa;
  }
  textarea, input {
    font-family: Source Sans Pro, Sans-Serif;
    font-size: 18px;
  }
`
const breakpoints = {
  xsmall: 468,
  small: 768,
  medium: 992,
  large: 1024,
  xlarge: Infinity,
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <BreakpointsProvider breakpoints={breakpoints}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </BreakpointsProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
