import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  display: none;

  ${({ theme }) => theme.sm`
    display: block;
    background: ${({ theme }) => theme.primary};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: ${({ theme }) => theme.fontWeights[4]};
    color: ${({ theme }) => theme.highlight};
    height: 100px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  `}
`

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textGrey};
  p {
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.textGrey};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    &:hover {
      color: ${({ theme }) => theme.highlight};
    }
  }
`

const FooterLogin: React.FC = () => {
  return (
    <StyledFooter>
      <StyledText>
        <p>
          <a
            href="https://www.inrikta.se/villkorenkat"
            target="_blank"
            rel="noopener noreferrer"
          >
            Användarvillkor
          </a>
          &nbsp;och&nbsp;
          <a
            href="https://www.inrikta.se/integritet"
            target="_blank"
            rel="noopener noreferrer"
          >
            behandling av personuppgifter
          </a>
          .
        </p>
      </StyledText>
    </StyledFooter>
  )
}

export default FooterLogin
