import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { getUser, checkStatus } from '../services/Api'
import CircleLoader from '../components/CircleLoader'
import BtnDefault from '../components/common/BtnDefault'
import LayoutLoginPage from '../components/LayoutLoginPage'
import { StyledH1 } from '../styles/typography'
import imageSrc from '../images/bankid_low_rgb.png'

const Card = styled.section`
  position: relative;
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[2]};
  padding-bottom: 0;
  border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;
  height: 86%;
  width: 100%;
  ${({ theme }) => theme.sm`
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    height: auto;
    border-radius: ${({ theme }) => theme.borderRadius.form};
    padding: ${({ theme }) => theme.space[3]};
  `}
`

const StyledForm = styled.form`
  padding-bottom: ${({ theme }) => theme.space[1]};
  display: flex;
  flex-direction: column;
  position: relative;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -46%);
  ${({ theme }) => theme.sm`
    top: 0%;
  `}
  img {
    width: 40px;
    height: auto;
    margin: 0 auto;
    ${({ theme }) => theme.sm`
      width: 60px;
    `}
  }
  ${({ theme }) => theme.sm`
    top: unset;
    left: unset;;
    transform: unset;
  `}
`

const InputWrapper = styled.div`
  position: relative;
  span {
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    padding-left: ${({ theme }) => theme.space[0]};
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.highlight};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    &:hover {
      color: ${({ theme }) => theme.text};
    }
  }
`

const InputWrapperCheckbox = styled(InputWrapper)`
  display: flex;
  margin-top: ${({ theme }) => theme.space[3]};
`

const ErrorMessageForm = styled.p`
  padding-top: 2px;
  padding-left: 2px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  position: absolute;
`

const ErrorMessageCheckbox = styled(ErrorMessageForm)`
  padding-top: 2px;
  padding-left: 2px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  position: relative;
`

const StyledHeader = styled(StyledH1)`
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.space[1]};
  padding-top: ${({ theme }) => theme.space[2]};
`

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  width: 100%;
  padding-top: ${({ theme }) => theme.space[2]};
`

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  height: 76px;
  margin-top: ${({ theme }) => theme.space[2]};
`

const InputSSL = styled.input`
  width: 100%;
  height: 46px;
  background: ${({ theme }) => theme.primary};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.card};
  border: 1px solid ${({ theme }) => theme.border};
  padding: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  margin-top: ${({ theme }) => theme.space[0]};
`

const StyledCheckbox = styled.input`
  display: flex;
  align-self: start;
`

const Login: React.FC = () => {
  const { register, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)
  const [checkingUser, setCheckingUser] = useState(false)
  const [userExists, setUserExists] = useState(false)
  const [error, setError] = useState(false)
  const [serverError, setServerError] = useState(null)

  const getStatus = async () => {
    setCheckingUser(true)
    setUserExists(false)
    try {
      const response = await checkStatus()
      setCheckingUser(false)
      setUserExists(true)
      window.open(response, '_self')
    } catch (err) {
      setCheckingUser(false)
      setUserExists(false)
    }
  }

  const setUser = async () => {
    setLoading(true)
    try {
      const response = await getUser()
      setLoading(false)
      window.open(response, '_self')
    } catch (err) {
      setLoading(false)
      setError(true)
      setServerError('Något gick fel vid inloggningen. Var vänlig försök igen.')
    }
  }

  const Container = styled.div`
    padding-top: ${({ theme }) => theme.space[4]};
  `

  const onSubmit = async () => {
    setUser()
  }

  useEffect(() => {
    getStatus()
  }, [])

  console.log(errors)

  return (
    <LayoutLoginPage>
      {checkingUser ? (
        <Container>
          <CircleLoader />
        </Container>
      ) : !userExists ? (
        <Card>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <img src={imageSrc} alt="bankid logo" />
            <StyledHeader>Logga in</StyledHeader>
            {errors.terms && (
              <ErrorMessageCheckbox>
                {errors.terms.message}
              </ErrorMessageCheckbox>
            )}
            <BtnContainer>
              {loading ? (
                <CircleLoader />
              ) : (
                <BtnDefault type="submit">Logga in</BtnDefault>
              )}
            </BtnContainer>
            <InputWrapperCheckbox>
              <StyledCheckbox
                type="checkbox"
                name="terms"
                placeholder="terms"
                ref={register({
                  required: 'Du behöver godkänna villkoren för att fortsätta',
                })}
              />
              <span>
                Jag godkänner&nbsp;
                <a
                  href="https://www.inrikta.se/villkorenkat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  användarvillkoren
                </a>
                &nbsp;och samtycker till behandlingen av mina&nbsp;
                <a
                  href="https://www.inrikta.se/integritet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  personuppgifter
                </a>
                .
              </span>
            </InputWrapperCheckbox>
          </StyledForm>
        </Card>
      ) : null}
    </LayoutLoginPage>
  )
}

export default Login
