import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LOGIN } from '../../utils/urlRoutes'
import { logoutUser } from '../../services/Api'
import BtnLogout from '../common/BtnLogout'
import { useHistory } from 'react-router-dom'
import { useAuthDataContext } from '../../context/AuthContext'

const Grid = styled.div`
  display: grid;
  background: ${({ theme }) => theme.highlight};
  grid-template-columns: repeat(2, 1fr) 120px;
  grid-gap: 5px;
  width: 100%;
  align-items: end;
  grid-template-rows: 60px;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space[2]};
`

const StyledLogo = styled.div`
  color: ${({ theme }) => theme.highlightlight};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  margin-bottom: 0;
  ${({ theme }) => theme.xs`
    font-size: ${({ theme }) => theme.fontSizes[4]};
    margin-bottom: 0;
  `}
  ${({ theme }) => theme.sm`
    font-size: ${({ theme }) => theme.fontSizes[4]};
  `}
`

const StyledUser = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  box-shadow: ${({ theme }) => theme.boxShadow};
  letter-spacing: 0.5px;
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  background: ${({ theme }) => theme.highlightlight};
  color: ${({ theme }) => theme.highlightdark};
  margin-bottom: ${({ theme }) => theme.space[1]};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`
const UserWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media print {
    display: none;
  }
`

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.space[1]};
  margin-bottom: 0;
  @media print {
    display: none;
  }
`

const Navigation: React.FC = () => {
  const history = useHistory()
  const { authData, removeAuth } = useAuthDataContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [initials, setInitials] = useState([])

  useEffect(() => {
    // ** Set initials if patient exists ** //
    if (authData !== null) {
      const contextUser = [authData.name]
      const getInitials = fullName => {
        const allNames = fullName.trim().split(' ')
        const initials = allNames.reduce((acc, curr, index) => {
          if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`
          }
          return acc
        }, '')
        return initials
      }
      let me = contextUser.map(getInitials)
      setInitials(me)
    }
  }, [authData])

  // ** Logout ** //
  const logout = async () => {
    try {
      const response = await logoutUser()
      if (response.status === 200) {
        setLoading(false)
        removeAuth()
        history.push(LOGIN.path)
      }
    } catch (err) {
      setError(true)
      setServerError(err.message)
    }
  }

  return (
    <Grid>
      <StyledLogo>ePBI tool</StyledLogo>
      {authData && initials ? (
        <UserWrapper>
          <StyledUser>{initials}</StyledUser>
        </UserWrapper>
      ) : (
        <UserWrapper />
      )}

      <BtnWrapper>
        <BtnLogout onClick={() => logout()}>Logga ut</BtnLogout>
      </BtnWrapper>
    </Grid>
  )
}

export default Navigation
