import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import { useToasts } from 'react-toast-notifications'
import { useAuthDataContext } from '../../context/AuthContext'
import IdleTimerModal from './IdleTimerModal'
import { LOGIN } from '../../utils/urlRoutes'
import { logoutUser } from '../../services/Api'

// ** Idle timer for automatic logout - on idle 15 min, popup
const IdleTimerContainer = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const { removeAuth } = useAuthDataContext()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)

  // ** Popup - on idle, logout after 30 seconds
  const onIdle = () => {
    setShow(true)
    sessionTimeoutRef.current = setTimeout(logout, 1000 * 60 * 0.5)
  }

  const logout = async () => {
    try {
      const response = await logoutUser()
      if (response.status === 200) {
        setLoading(false)
        setShow(false)
        clearTimeout(sessionTimeoutRef.current)
        removeAuth()
        history.push(LOGIN.path)
      }
    } catch (err) {
      setLoading(false)
      clearTimeout(sessionTimeoutRef.current)
      addToast('Något gick fel. Försök igen.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const stayActive = () => {
    setShow(false)
    clearTimeout(sessionTimeoutRef.current)
  }

  return (
    <>
      <IdleTimerModal
        show={show}
        logout={logout}
        stayActive={stayActive}
        loading={loading}
      />
      <IdleTimer ref={idleTimerRef} timeout={1000 * 60 * 15} onIdle={onIdle} />
    </>
  )
}

export default IdleTimerContainer
