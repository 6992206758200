import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.nav`
  display: none;
  ${({ theme }) => theme.sm`
    display: flex;
    height: 60px;
    padding-left: ${({ theme }) => theme.fontSizes[4]};
    align-items: center;
    background: ${({ theme }) => theme.primary};
  `}
`

const StyledLogo = styled.div`
  color: ${({ theme }) => theme.highlightlight};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[4]};
  ${({ theme }) => theme.sm`
    font-size: ${({ theme }) => theme.fontSizes[4]};
  `}
`

const NavigationLogin: React.FC = () => {
  return (
    <>
      <Wrapper>
        <StyledLogo>ePBI tool</StyledLogo>
      </Wrapper>
    </>
  )
}

export default NavigationLogin
