import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import BtnDefault from './common/BtnDefault'
import CircleLoader from './CircleLoader'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
  StyledH3,
  StyledParagraph,
  StyledParagraphBold,
} from '../styles/typography'
import { sendFormInvite } from '../services/Api'

const Background = styled.div`
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.secondary};
`

const Card = styled.div`
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.borderRadius.form};
  height: auto;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 90vw;
  max-width: 400px;
  opacity: 1;
`

const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  text-align: center;
`

const BtnWrapperSmall = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-top: ${({ theme }) => theme.space[5]};
  button {
    max-width: 140px;
    &:first-of-type {
      margin-right: ${({ theme }) => theme.space[1]};
    }
    &:last-of-type {
      margin-left: ${({ theme }) => theme.space[1]};
    }
  }
`

const ErrorMessageForm = styled.p`
  color: ${({ theme }) => theme.error};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  position: absolute;
  top: -20px;
  left: 0;
  transform: translateY(60px);
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  transition: ${({ theme }) => theme.transitions.all};
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.space[3]};
  p {
    margin-top: ${({ theme }) => theme.space[2]};
  }
`

const InputLabel = styled.label`
  margin-bottom: 15px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  &:focus-within > span,
  input:not(:placeholder-shown) + span {
    transform: translateY(6px);
    font-size: ${({ theme }) => theme.fontSizes[0]};
    color: ${({ theme }) => theme.highlight};
  }
`

const StyledInput = styled.input`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[0]};
  margin-top: ${({ theme }) => theme.space[1]};
  border: none;
  outline: none;
  &::placeholder {
    opacity: 0;
  }
`

const StyledSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(20px);
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  color: ${({ theme }) => theme.text};
  transition: ${({ theme }) => theme.transitions.all};
`

// ** Add new PNQ form ** //
// firstRelFormID: null
const AddPnqModal = ({ showPnq, setShowPnq, rfId, name, ssid }) => {
  const { register, handleSubmit, errors } = useForm()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const invitePatient = async (patientSSID, formID, firstRelFormID, email) => {
    setLoading(true)
    const response = await sendFormInvite(
      patientSSID,
      formID,
      firstRelFormID,
      email
    )
    if (response.status === 200) {
      setShowPnq(false)
      addToast('Formulär skickat', { appearance: 'success', autoDismiss: true })
      const interval = setInterval(() => {
        window.location.reload()
      }, 2000)
      return () => clearInterval(interval)
    } else {
      setLoading(false)
      addToast('Något gick fel. Försök igen.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  // For PNQ form use firstRelFormID: null
  const onSubmit = async (formData: any) => {
    invitePatient(ssid, 1, null, formData.email)
  }

  return (
    showPnq && (
      <>
        <Background />

        <Card>
          <StyledSectionHeading>Starta ny utvärdering</StyledSectionHeading>
          <StyledParagraph>
            Ange din e-post adress och acceptera för att starta ett nytt
            PNQ-formulär.
          </StyledParagraph>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {name && ssid && (
              <>
                <StyledParagraphBold>
                  {ssid} {name}
                </StyledParagraphBold>
              </>
            )}
            <InputLabel htmlFor="email">
              <StyledInput
                name="email"
                placeholder="E-post*"
                ref={register({
                  required: 'Ange e-post',
                  pattern: {
                    value: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                    message: 'Inte en giltig e-post adress',
                  },
                })}
              />
              <StyledSpan>E-post*</StyledSpan>
              {errors.email && (
                <ErrorMessageForm>{errors.email.message}</ErrorMessageForm>
              )}
            </InputLabel>
            <BtnWrapperSmall>
              {loading ? (
                <CircleLoader />
              ) : (
                <>
                  <BtnDefault small type="submit">
                    Acceptera
                  </BtnDefault>
                  <BtnDefault small onClick={() => setShowPnq(!showPnq)}>
                    Avbryt
                  </BtnDefault>
                </>
              )}
            </BtnWrapperSmall>
          </StyledForm>
        </Card>
      </>
    )
  )
}

export default AddPnqModal
