import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory, useParams } from 'react-router-dom'
import { HOME_PATIENT } from '../utils/urlRoutes'
import { Link } from 'react-router-dom'
import { getPatientForm, postForm } from '../services/Api'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'
import {
  StyledH1,
  StyledH3,
  StyledParagraph,
  StyledParagraphBold,
  StyledParagraphSmall,
} from '../styles/typography'
import LayoutHomePage from '../components/LayoutHomePage'
import IdleTimerContainer from '../components/IdleTimer/IdleTimerContainer'
import BtnDefault from '../components/common/BtnDefault'
import CircleLoader from '../components/CircleLoader'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface ParamTypes {
  id: string
}

interface Props {
  sectionHeight: number
}

const StyledParagraphSmallMobile = styled(StyledParagraphSmall)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  ${({ theme }) => theme.sm`
    display: none;
  `}
`
const StyledParagraphSmallDesktop = styled(StyledParagraphSmall)`
  display: none;
  ${({ theme }) => theme.sm`
    display: block;
  `}
`

const StyledMainQuestion = styled(StyledParagraphBold)`
  ${({ theme }) => theme.sm`
    margin-top: 30px;
  `}
`

const CardMobile = styled.div`
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[3]};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  height: auto;
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin: ${({ theme }) => theme.space[2]} 0;
  ${({ theme }) => theme.sm`
    padding: unset;
    position: unset;
    border-radius: unset;
    height: unset;
    margin: unset;
    box-shadow: unset;
  `}
`

const FormCard = styled.section<Props>`
  display: block;
  ${({ theme }) => theme.sm`
  position: relative;
  overflow-y: scroll;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  background: ${({ theme }) => theme.background};
  margin-bottom: ${({ theme }) => theme.space[6]};
    background: ${({ theme }) => theme.primary};
    overflow-y: scroll;
    border-radius: 0 0 8px 8px;
    height: ${props => props.sectionHeight}px;
    box-shadow: ${({ theme }) => theme.boxShadow};
    min-height: 500px;
    max-height: 700px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid ${({ theme }) => theme.primary};
      background-color: rgba(0, 0, 0, 0.2);
    }
    div {
      &:nth-child(odd) {
      background: ${({ theme }) => theme.backgroundlight};
    }}
  `}
`

const Card = styled.div`
  background: ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[3]};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin: ${({ theme }) => theme.space[3]} 0;
`

const Grid2ColHeader = styled.div`
  display: none;
  ${({ theme }) => theme.sm`
    display: grid;
    grid-template-columns: 66% repeat(6, minmax(30px, 1fr));
    grid-gap: 6px;
    width: 100%;
    padding: 0 ${({ theme }) => theme.space[3]};
    padding-left: 0;
    margin-top: 48px;
  `}
`

const MobileMainQuestion = styled.div`
  display: block;
  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[0]};
  ${({ theme }) => theme.sm`
    display: none;
  `}
`

const StyledHeading = styled(StyledH1)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[4]};
`

const StyledLine = styled.hr`
  display: none;
  ${({ theme }) => theme.sm`
    display: block;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.highlight};
    height: 1px;
    color: ${({ theme }) => theme.highlight};
    background-color: ${({ theme }) => theme.highlight};
    margin-bottom: 0;
  `}
`

const StyledLabel = styled.label`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
  transform: rotate(-60deg);
  white-space: nowrap;
  padding-top: 24px;
  ${({ theme }) => theme.sm`
    transform: rotate(-60deg);
    white-space: nowrap;
    padding-top: 24px;
  `}
`

const StyledLabelItalic = styled(StyledLabel)`
  font-style: italic;
`

const Grid2Col = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: ${({ theme }) => theme.space[2]};
  width: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.space[2]};
  ${({ theme }) => theme.sm`
    grid-template-columns: 66% repeat(6, minmax(30px, 1fr));
    grid-gap: 0px;
    width: 100%;
    padding: ${({ theme }) => theme.space[1]} 0;
    padding-left: ${({ theme }) => theme.space[3]};
    grid-template-rows: unset;
    align-items: unset;
    margin-top: unset;
  `}
`

const StyledLabelMobile = styled.label`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
  ${({ theme }) => theme.sm`
    display: none;
  `}
`
const StyledLabelMobileItalic = styled(StyledLabelMobile)`
  font-style: italic;
`

const ErrorMessage = styled.p`
  padding-top: 2px;
  padding-left: 2px;
  color: ${({ theme }) => theme.error};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes[0]};
`

const Wrapper = styled.div`
  position: relative;
  align-self: center;
  width: 20px;
  height: 20px;
`

const RadioButton = styled.input`
  z-index: 1;
  cursor: pointer;
  &[type='radio'] {
    position: absolute;
    width: 20px;
    height: 20px;
    transition: ${({ theme }) => theme.transitions.all};
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
    transition: ${({ theme }) => theme.transitions.all};
  }
  &:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
  }
  &:hover:before {
    background: ${({ theme }) => theme.highlightlight};
  }
`
const RadioButtonOrange = styled(RadioButton)`
  &:before {
    transition: ${({ theme }) => theme.transitions.all};
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.pop};
  }
  &:checked:before {
    background: ${({ theme }) => theme.pop};
  }
  &:hover:before {
    background: ${({ theme }) => theme.pop};
  }
`

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space[4]};
  ${({ theme }) => theme.sm`
    margin: 0;
  `}
  button {
    margin-right: ${({ theme }) => theme.space[0]};
  }
  a {
    margin-left: ${({ theme }) => theme.space[0]};
  }

  button,
  a {
    max-width: 200px;
  }
`
const StyledBackLink = styled(Link)`
  padding-left: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-decoration: none;
  position: relative;
  transition: ${({ theme }) => theme.transitions.all};
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.highlight};
  }
  &:before {
    border-color: ${({ theme }) => theme.highlight};
    border-style: solid;
    border-width: 0 0 3px 3px;
    content: '';
    display: block;
    height: 6px;
    left: 0px;
    position: absolute;
    width: 6px;
    margin-top: -1px;
    top: 6px;
    transform: rotate(45deg);
    transition: ${({ theme }) => theme.transitions.all};
  }
`
const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: 0 ${({ theme }) => theme.space[3]};
`
const StyledTextLeft = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  margin: 0 ${({ theme }) => theme.space[3]};
`

const StyledTextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin-top: ${({ theme }) => theme.space[3]};
`

const StyledTextLeftBold = styled(StyledTextLeft)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[1]};
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
`
const InputLabel = styled.label`
  margin-bottom: 15px;
  width: 30%;
  position: relative;
  margin-right: ${({ theme }) => theme.space[4]};
  border-bottom: 1px solid ${({ theme }) => theme.border};
  &:focus-within > span,
  input:not(:placeholder-shown) + span {
    transform: translateY(6px);
    font-size: ${({ theme }) => theme.fontSizes[0]};
    color: ${({ theme }) => theme.highlight};
  }
  .react-datepicker-wrapper {
    .input {
      font-size: ${({ theme }) => theme.fontSizes[2]};
      font-weight: ${({ theme }) => theme.fontWeights[0]};
      padding-top: ${({ theme }) => theme.space[2]};
      padding-bottom: ${({ theme }) => theme.space[0]};
      margin-top: ${({ theme }) => theme.space[1]};
      border: none;
      background-color: transparent;
      outline: none;
    }
  }
`

const StyledInput = styled.input`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[0]};
  margin-top: ${({ theme }) => theme.space[1]};
  border: none;
  background-color: transparent;
  outline: none;
  &::placeholder {
    opacity: 0;
  }
`
const StyledSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(20px);
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  color: ${({ theme }) => theme.text};
  transition: ${({ theme }) => theme.transitions.all};
`
const ErrorMessageForm = styled.p`
  padding-top: 2px;
  padding-left: 2px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  position: absolute;
`

const NewForms: React.FC = () => {
  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [newForm, setNewForm] = useState(null)
  const [isPnq, setIsPnq] = useState(null)
  const { register, handleSubmit, errors, control, getValues } = useForm()
  const [height, setHeight] = useState(window.innerHeight)
  const [formValues, setFormValues] = useState({})
  const [selectedValues, setSelectedValues] = useState([])
  const [selectedQuestions, setSelectedQuestions] = useState([])

  const updateHeight = () => {
    setHeight(window.innerHeight - 560)
  }
  useEffect(() => {
    updateHeight()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  })

  const postNewForm = async (rfId, answers, name, startDate) => {
    const config = {
      rfId,
      answers,
      treatment: {
        name,
        startDate,
      },
    }
    setLoading(true)
    try {
      const response = await postForm(config)
      if (response.status === 200) {
        setLoading(false)
        addToast('Formulär skickat', {
          appearance: 'success',
          autoDismiss: true,
        })
        history.push(HOME_PATIENT.path)
      }
    } catch (err) {
      setLoading(false)
      if (err.message === 'Error: Request failed with status code 400') {
        setLoading(false)
        addToast('Felaktig begäran', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else if (err.message === 'Error: Request failed with status code 401') {
        setLoading(false)
        addToast('Ej autentiserad', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setLoading(false)
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      history.push(HOME_PATIENT.path)
    }
  }

  const fetchForm = async id => {
    setLoading(true)
    try {
      const response = await getPatientForm(id)
      if (response.data.answersExist) {
        addToast('Felaktig begäran.', {
          appearance: 'error',
          autoDismiss: true,
        })
        history.push(HOME_PATIENT.path)
      }
      if (!response.data.answersExist) {
        setNewForm(response.data.form)
        setIsPnq(response.data.isPNQ)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      if (err.message === 'Error: Request failed with status code 400') {
        setLoading(false)
        addToast('Felaktig begäran', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else if (err.message === 'Error: Request failed with status code 401') {
        setLoading(false)
        addToast('Ej autentiserad', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setLoading(false)
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      history.push(HOME_PATIENT.path)
    }
  }
  // moment(answers.startDate).format('YYYY-MM-DD')
  const onSubmit = answers => {
    const { name, startDate, ...filteredAnswers } = answers
    postNewForm(
      parseInt(id),
      filteredAnswers,
      answers.name ? answers.name : null,
      answers.startDate ? moment(answers.startDate).format() : null
    )
  }

  useEffect(() => {
    fetchForm(id)
  }, [])

  useEffect(() => {
    const getKey = data => {
      const arr = [],
        obj = Object.keys(data)
      for (var x in obj) {
        if (data[obj[x]] === '3' || data[obj[x]] === '4') {
          arr.push(obj[x])
        }
      }
      return setSelectedValues(arr)
    }
    getKey(formValues)
  }, [formValues])

  useEffect(() => {
    let questionList = newForm?.questions
    let valueList = selectedValues
    if (newForm) {
      var objectsFound = []
      for (var i = 0; i < Object.keys(questionList).length; i++) {
        for (var j = 0; j < valueList.length; j++) {
          if (questionList[i].id === Number(valueList[j])) {
            objectsFound.push(questionList[i])
          }
        }
      }
    }
    setSelectedQuestions(objectsFound)
  }, [selectedValues])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutHomePage>
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <IdleTimerContainer />
          <StyledBackLink to={HOME_PATIENT.path}>Tillbaka</StyledBackLink>
          {newForm && (
            <>
              {isPnq ? (
                <>
                  <StyledHeading>{newForm.titlePnq}</StyledHeading>
                  <StyledParagraph>{newForm.descriptionPnq}</StyledParagraph>
                </>
              ) : (
                <>
                  <StyledHeading>{newForm.titlePbq}</StyledHeading>
                  <StyledParagraph>{newForm.descriptionPbq}</StyledParagraph>
                </>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                {!isPnq && (
                  <>
                    <StyledTextBold>
                      Behandling jag står på och sedan när:
                    </StyledTextBold>
                    <FormContainer>
                      <InputLabel htmlFor="name">
                        <StyledInput
                          name="name"
                          placeholder="Behandling*"
                          ref={register({
                            required: 'Ange behandling',
                          })}
                        />
                        <StyledSpan>Behandling*</StyledSpan>
                        {errors.name && (
                          <ErrorMessageForm>
                            {errors.name.message}
                          </ErrorMessageForm>
                        )}
                      </InputLabel>

                      <Controller
                        control={control}
                        defaultValue={null}
                        name="startDate"
                        rules={{ required: 'Ange startdatum för behandlingen' }}
                        render={props => (
                          <InputLabel htmlFor="startDate">
                            <ReactDatePicker
                              className="input"
                              placeholderText="ÅÅÅÅ MM DD*"
                              name="startDate"
                              onChange={e => props.onChange(e)}
                              selected={props.value}
                            />
                            {errors.startDate && (
                              <ErrorMessageForm>
                                {errors.startDate.message}
                              </ErrorMessageForm>
                            )}
                          </InputLabel>
                        )}
                      />
                    </FormContainer>
                  </>
                )}

                <MobileMainQuestion>
                  {isPnq ? (
                    <StyledMainQuestion>
                      {newForm.mainQuestionPnq}
                    </StyledMainQuestion>
                  ) : (
                    <StyledMainQuestion>
                      {newForm.mainQuestionPbq}
                    </StyledMainQuestion>
                  )}
                </MobileMainQuestion>

                {/* desktop only */}
                <Grid2ColHeader>
                  {isPnq ? (
                    <StyledMainQuestion>
                      {newForm.mainQuestionPnq}
                    </StyledMainQuestion>
                  ) : (
                    <StyledMainQuestion>
                      {newForm.mainQuestionPbq}
                    </StyledMainQuestion>
                  )}
                  <StyledLabel htmlFor="0">Inte alls</StyledLabel>
                  <StyledLabel htmlFor="1">Lite</StyledLabel>
                  <StyledLabel htmlFor="2">Till viss del</StyledLabel>
                  <StyledLabel htmlFor="3">Ganska mycket</StyledLabel>
                  <StyledLabel htmlFor="4">Mycket</StyledLabel>
                  <StyledLabelItalic htmlFor="5">
                    Ej tillämpligt
                  </StyledLabelItalic>
                </Grid2ColHeader>
                <StyledLine />

                <FormCard sectionHeight={height}>
                  {newForm.questions.map(({ id, title }) => (
                    <CardMobile key={id}>
                      {/* mobile only */}
                      <StyledParagraphSmallMobile>
                        {title}
                      </StyledParagraphSmallMobile>

                      <Grid2Col>
                        {/* desktop only */}
                        <StyledParagraphSmallDesktop>
                          {title}
                        </StyledParagraphSmallDesktop>

                        <Wrapper>
                          <RadioButton
                            name={id}
                            type="radio"
                            value="0"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobile htmlFor="0">
                          Inte alls
                        </StyledLabelMobile>

                        <Wrapper>
                          <RadioButton
                            name={id}
                            type="radio"
                            value="1"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobile htmlFor="1">Lite</StyledLabelMobile>

                        <Wrapper>
                          <RadioButton
                            name={id}
                            type="radio"
                            value="2"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobile htmlFor="2">
                          Till viss del
                        </StyledLabelMobile>

                        <Wrapper>
                          <RadioButton
                            name={id}
                            type="radio"
                            value="3"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobile htmlFor="3">
                          Ganska mycket
                        </StyledLabelMobile>

                        <Wrapper>
                          <RadioButton
                            name={id}
                            type="radio"
                            value="4"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobile htmlFor="4">
                          Mycket
                        </StyledLabelMobile>

                        <Wrapper>
                          <RadioButtonOrange
                            name={id}
                            type="radio"
                            value="5"
                            ref={register({ required: true })}
                            onChange={() => {
                              const values = getValues()
                              setFormValues(values)
                            }}
                          />
                        </Wrapper>
                        <StyledLabelMobileItalic htmlFor="5">
                          Ej tillämpligt
                        </StyledLabelMobileItalic>

                        {errors[id] && (
                          <ErrorMessage>
                            {'Du måste välja ett alternativ'}
                          </ErrorMessage>
                        )}
                      </Grid2Col>
                    </CardMobile>
                  ))}
                </FormCard>

                {selectedQuestions && selectedQuestions.length > 0 ? (
                  <Card>
                    {isPnq ? (
                      <>
                        <StyledSectionHeading>
                          Mina viktigaste behandlingsmål
                        </StyledSectionHeading>
                      </>
                    ) : (
                      <>
                        <StyledSectionHeading>
                          Behandlingen har hjälpt mig mest med ...
                        </StyledSectionHeading>
                      </>
                    )}

                    {selectedQuestions.map(({ title }) => (
                      <StyledTextLeftBold key={title}>
                        {title}
                      </StyledTextLeftBold>
                    ))}
                  </Card>
                ) : null}

                <BtnWrapper>
                  <BtnDefault type="submit">Skicka</BtnDefault>
                  <BtnDefault to={HOME_PATIENT.path}>Avbryt</BtnDefault>
                </BtnWrapper>
              </form>
            </>
          )}
        </>
      )}
    </LayoutHomePage>
  )
}

export default NewForms
