import React from 'react'
import { useLocation } from 'react-router-dom'
import Navigation from './Navigation'
import NavigationLogin from './NavigationLogin'

const Header = () => {
  const { pathname } = useLocation()
  return (
    <>
      {pathname === '/login' ||
      pathname === '/' ||
      pathname === '/error' ||
      pathname === '/not-found' ? (
        <NavigationLogin />
      ) : (
        <Navigation />
      )}
    </>
  )
}

export default Header
