import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  min-height: calc(100vh - 160px);
  width: 100vw;
  position: relative;
  padding-bottom: ${({ theme }) => theme.space[6]};
  ${({ theme }) => theme.sm`
    padding-bottom: ${({ theme }) => theme.space[7]};
  `}
  @media print {
    @page {
      size: landscape;
    }
    nav,
    footer {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};
  padding-top: ${({ theme }) => theme.space[4]};
  max-width: 1100px;

  ${({ theme }) => theme.sm`
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.space[4]};
    padding-top: ${({ theme }) => theme.space[4]};
    max-width: 1164px;
  `}

  ${({ theme }) => theme.xl`
    margin: 0 auto;
    padding-top: ${({ theme }) => theme.space[4]};
  `}
`

const Layout = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  )
}
export default Layout
