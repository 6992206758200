import React from 'react'
import styled from 'styled-components'
import { NEWFORM_ID, ANSWEREDFORM_ID } from '../utils/urlRoutes'
import {
  StyledH3,
  StyledParagraphBold,
  StyledParagraph,
} from '../styles/typography'
import Card from './common/Card'
import BtnDefault from './common/BtnDefault'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'

interface IProps {
  rfId?: any
  pnq?: any
  pbq?: any
  addForm?: any
  categories?: any
}

const HighlightText = styled.div`
  color: ${({ theme }) => theme.highlight};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  text-decoration: none;
  display: flex;
  letter-spacing: 0.5px;
`

const BtnWrapperSmall = styled.div`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[3]};
  button,
  a {
    max-width: 220px;
  }
  ${({ theme }) => theme.xs`
    justify-content: flex-start;
    padding-left: ${({ theme }) => theme.space[1]};

    button,
    a {
      max-width: 220px;
      margin-left: ${({ theme }) => theme.space[2]};
    }
  `}
`
const Grid3Col = styled.div`
  display: grid;
  grid-template-columns: 2fr minmax(100px, 1fr) 140px;
  grid-gap: 5px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[3]};
  align-items: center;
`
const Grid3ColInfo = styled(Grid3Col)`
  padding-bottom: 0;
  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.primary};
  }
`

const StyledText = styled.p`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
`

const StyledInfo = styled(StyledParagraphBold)`
  padding-bottom: ${({ theme }) => theme.space[1]};
`

const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: 0 ${({ theme }) => theme.space[3]};
`

const StyledSectionHeadingMT = styled(StyledParagraph)`
  padding-left: ${({ theme }) => theme.space[3]};
  margin-top: ${({ theme }) => theme.space[4]};
`
const StyledLink = styled(Link)`
  display: block;
  color: #4c74b9;
  text-decoration: none;
  padding: ${({ theme }) => theme.space[1]} 0;
  transition: ${({ theme }) => theme.transitions.all};
  span {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    padding-right: ${({ theme }) => theme.space[2]};
  }
  &:hover {
    background: ${({ theme }) => theme.backgroundlighthover};
  }
  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.backgroundlight};
    transition: ${({ theme }) => theme.transitions.all};
    &:hover {
      background: ${({ theme }) => theme.backgroundlighthover};
    }
  }
`

const FormCardDesktop: React.FC<IProps> = ({ rfId, pnq, pbq, addForm }) => {
  return (
    <>
      <Card>
        <StyledSectionHeading>PBI-formulär</StyledSectionHeading>
        <Grid3ColInfo>
          <StyledInfo>Formulär</StyledInfo>
          <StyledInfo>Datum</StyledInfo>
          <StyledInfo>Status</StyledInfo>
        </Grid3ColInfo>
        {pbq.length > 0 ? (
          <>
            {pbq
              .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
              .map(({ created_at, score, rfId, scheduledFor }, index) => (
                <Fragment key={index}>
                  {score && score !== null ? (
                    <StyledLink to={`${ANSWEREDFORM_ID.getPathWithId(rfId)}`}>
                      <Grid3Col>
                        <StyledText>PBQ, Behandlingens nytta</StyledText>
                        <StyledText>
                          {moment(created_at).format('YYYY-MM-DD')}
                        </StyledText>
                        {/* <StyledText>Se resultat</StyledText> */}
                      </Grid3Col>
                    </StyledLink>
                  ) : (
                    <>
                      <StyledLink to={`${NEWFORM_ID.getPathWithId(rfId)}`}>
                        <Grid3Col>
                          <StyledText>PBQ, Behandlingens nytta</StyledText>
                          <StyledText>
                            {moment(created_at).format('YYYY-MM-DD')}
                          </StyledText>
                          {scheduledFor ? (
                            <HighlightText>
                              Nytt&nbsp;{scheduledFor.scheduledFor}
                            </HighlightText>
                          ) : (
                            <HighlightText>Nytt</HighlightText>
                          )}
                        </Grid3Col>
                      </StyledLink>
                    </>
                  )}
                </Fragment>
              ))}
          </>
        ) : null}

        {pnq ? (
          <>
            {pnq.score === null ? (
              <StyledLink to={`${NEWFORM_ID.getPathWithId(rfId)}`}>
                <Grid3Col>
                  <StyledText>PNQ, Behandlingsmålens betydelse</StyledText>
                  <StyledText>
                    {moment(pnq.created_at).format('YYYY-MM-DD')}
                  </StyledText>
                  <HighlightText>Nytt</HighlightText>
                </Grid3Col>
              </StyledLink>
            ) : (
              <StyledLink to={`${ANSWEREDFORM_ID.getPathWithId(rfId)}`}>
                <Grid3Col>
                  <StyledText>PNQ, Behandlingsmålens betydelse</StyledText>
                  <StyledText>
                    {moment(pnq.created_at).format('YYYY-MM-DD')}
                  </StyledText>
                  {/* <StyledText>Se resultat</StyledText> */}
                </Grid3Col>
              </StyledLink>
            )}
          </>
        ) : null}

        {pnq && pnq.score !== null ? (
          <>
            <StyledSectionHeadingMT>
              Vill du schemalägga nytt PBQ-formulär?
            </StyledSectionHeadingMT>
            <BtnWrapperSmall>
              <BtnDefault small onClick={() => addForm(rfId)}>
                Nytt PBQ-formulär
              </BtnDefault>
            </BtnWrapperSmall>
          </>
        ) : null}
      </Card>
    </>
  )
}
export default FormCardDesktop
