import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { HOME_PATIENT, LOGIN } from '../utils/urlRoutes'
import styled from 'styled-components'
import { StyledH3 } from '../styles/typography'
import Card from './common/Card'
import { getCategories } from '../services/Api'
import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

interface IProps {
  AVGScore: any
  CATScores: any
}

const StyledTextLeft = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  margin: 0 ${({ theme }) => theme.space[3]};
`

const StyledTextSmall = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  padding-left: ${({ theme }) => theme.space[3]};
  width: 100%;
  height: 60px;
  ${({ theme }) => theme.sm`
    height: 40px;
  `}
`

const StyledTextLeftBold = styled(StyledTextLeft)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[1]};
  margin-top: ${({ theme }) => theme.space[3]};
`

const HighlightText = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.highlight};
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  text-decoration: none;
  padding-right: ${({ theme }) => theme.space[3]};
  width: 100%;
  height: 60px;
  ${({ theme }) => theme.sm`
    height: 40px;
    justify-content: flex-start;
  `}
`

const StyledSectionHeading = styled(StyledH3)`
  margin: 0 ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`

const Grid6Col = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 60px);
  width: 100%;
  align-items: center;
  justify-items: left;
  div {
    &:nth-of-type(odd) {
      background: ${({ theme }) => theme.backgroundlight};
    }
  }
  ${({ theme }) => theme.sm`
    grid-template-rows: repeat(5, 40px);
  `}
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 66% repeat(1, 1fr);
  width: 100%;
  align-items: center;
  justify-items: left;
`

const StyledMainScore = styled.p`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[1]} 0;
`

const StyledTextMain = styled.p`
  padding-left: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
`

const HighlightTextMain = styled.p`
  color: ${({ theme }) => theme.highlight};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  text-decoration: none;
  padding-left: ${({ theme }) => theme.space[2]};
`

const ScoreTable: React.FC<IProps> = ({ AVGScore, CATScores }) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [categories, setCategories] = useState(null)

  const fetchCategories = async () => {
    try {
      const response = await getCategories()
      setCategories(response.data)
    } catch (err) {
      if (err.message === 'Error: Request failed with status code 401') {
        history.push(LOGIN.path)
      } else if (err.message === 'Error: Request failed with status code 500') {
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
        history.push(HOME_PATIENT.path)
      } else {
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <>
      <Card>
        <StyledSectionHeading>PBI-Patient Benefit Index</StyledSectionHeading>
        {AVGScore && (
          <StyledMainScore>
            <StyledTextMain>Totalt</StyledTextMain>
            <HighlightTextMain>
              {parseFloat(AVGScore).toFixed(2)}
            </HighlightTextMain>
          </StyledMainScore>
        )}
        <StyledTextLeftBold>Behandlingsnytta per område</StyledTextLeftBold>
        <Container>
          {categories && (
            <>
              <Grid6Col>
                {categories.map(({ categoryName }) => (
                  <StyledTextSmall key={categoryName}>
                    {categoryName}
                  </StyledTextSmall>
                ))}
              </Grid6Col>
            </>
          )}
          <Grid6Col>
            {CATScores.map(({ score, category_id }) => (
              <Fragment key={category_id}>
                {score === null ? (
                  <HighlightText></HighlightText>
                ) : (
                  <HighlightText>{parseFloat(score).toFixed(2)}</HighlightText>
                )}
              </Fragment>
            ))}
          </Grid6Col>
        </Container>

        <StyledTextLeftBold>
          Totala värdet ligger mellan 0 och 4.
        </StyledTextLeftBold>
        <StyledTextLeft>
          0-1: Enligt dina svar hade du inte tillräcklig nytta av behandlingen.
        </StyledTextLeft>
        <StyledTextLeft>
          1-2: Enligt dina svar hade du lite nytta av behandlingen.
        </StyledTextLeft>
        <StyledTextLeft>
          2-3: Enligt dina svar hade du ganska stor nytta av behandlingen, och
          uppnådde ett antal viktiga behandlingsmål, dock inte alla.
        </StyledTextLeft>
        <StyledTextLeft>
          3-4: Enligt dina svar hade du stor nytta av behandlingen, och uppnådde
          (nästan) alla dina behandlingsmål.
        </StyledTextLeft>
      </Card>
    </>
  )
}
export default ScoreTable
