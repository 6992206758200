import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  ${({ theme }) => theme.sm`
    height: 0;
    min-height: calc(100vh - 60px);
  `}
`

const StyledText = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.textGrey};
  p {
    font-size: ${({ theme }) => theme.fontSizes[0]};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
  }
  a {
    color: ${({ theme }) => theme.textGrey};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
  }
  ${({ theme }) => theme.sm`
    display: none;
  `}
`

const Layout = ({ children }) => {
  return (
    <Container>
      {children}
      <StyledText>
        <p>
          <a
            href="https://www.inrikta.se/villkorenkat"
            target="_blank"
            rel="noopener noreferrer"
          >
            Användarvillkor
          </a>
          &nbsp;och&nbsp;
          <a
            href="https://www.inrikta.se/integritet"
            target="_blank"
            rel="noopener noreferrer"
          >
            behandling av personuppgifter
          </a>
          .
        </p>
      </StyledText>
    </Container>
  )
}
export default Layout
