import React from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import styled from 'styled-components'
import { StyledH3 } from '../styles/typography'

export interface IScore {
  created_at: Date
  rfId: number
  score: number
}

export interface IProps {
  score?: IScore
  data?: Array<IScore>
}

const Container = styled.div`
  padding: ${({ theme }) => theme.space[2]} 0;
  margin-bottom: ${({ theme }) => theme.space[2]};
`
const StyledSectionHeading = styled(StyledH3)`
  margin: 0 ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`

const LineChart: React.FC<IProps> = ({ score, data }) => {
  const scores = data
    ? data.filter(s => s.score !== null).map(s => s.score)
    : [score.score]
  const dates = data
    ? data
        .filter(s => s.score !== null)
        .map(s => moment(s.created_at).format('YYYY-MM-DD'))
    : [moment(score.created_at).format('YYYY-MM-DD')]
  const gradient =
    'linear-gradient(180deg, #3FC600 0%, #E3E669 47.94%, #FFBB54 74.6%, #FB8970 90.17%)'
  const chartData = {
    labels: dates,
    datasets: [
      {
        label: 'Legend',
        data: scores,
        fill: false,
        backgroundColor: gradient,
        borderColor: '#212121',
        borderWidth: 1,
      },
    ],
  }

  const options = {
    plugins: {
      legend: false, // Hide legend
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Datum',
        },
      },
      y: {
        title: {
          display: true,
          text: 'PBI',
        },
        suggestedMin: 0,
        suggestedMax: 4,
      },
    },
  }

  return (
    <>
      <StyledSectionHeading>
        Patient Benefit Index över tid
      </StyledSectionHeading>
      <Line data={chartData} options={options} />
    </>
  )
}

export default LineChart
