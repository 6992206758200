// routes
export const LOGIN = {
  path: '/login',
}
export const ERROR = {
  path: '/error',
}
export const NOTFOUND = {
  path: '/not-found',
}

// patient routes
export const HOME_PATIENT = {
  path: '/patient/home',
}

export const NEWFORM_ID = {
  path: '/patient/newform/:id',
  getPathWithId: (id: number) => `/patient/newform/${id}`,
}

export const ANSWEREDFORM_ID = {
  path: '/patient/form/:id',
  getPathWithId: (id: number) => `/patient/form/${id}`,
}
