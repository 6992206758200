import React, { createContext, useContext, useState } from 'react'

/////////////////////////////////////////////////////////////////////
// AUTH CONTEXT
////////////////////////////////////////////////////////////////////

export const AuthDataContext = createContext(null)

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(null)
  const [isPatient, setIsPatient] = useState(false)

  const addAuth = newAuthData => {
    setAuthData(newAuthData)
  }

  const removeAuth = () => {
    setAuthData(null)
  }

  return (
    <AuthDataContext.Provider
      value={{
        authData,
        isPatient,
        setIsPatient,
        addAuth,
        removeAuth,
      }}
    >
      {props.children}
    </AuthDataContext.Provider>
  )
}

export const useAuthDataContext = () => useContext(AuthDataContext)

export default AuthDataProvider
