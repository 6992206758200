import React from 'react'
import styled from 'styled-components'
import ArrowDown from './icons/ArrowDown'
import { NEWFORM_ID, ANSWEREDFORM_ID } from '../utils/urlRoutes'
import {
  StyledH3,
  StyledParagraphBold,
  StyledParagraph,
} from '../styles/typography'
import BtnDefault from './common/BtnDefault'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'

interface IProps {
  rfId?: any
  pnq?: any
  pbq?: any
  addForm?: any
  categories?: any
}

const Card = styled.section`
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[3]};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[1]};
`

const HighlightText = styled.div`
  color: ${({ theme }) => theme.highlight};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  margin-left: ${({ theme }) => theme.space[2]};
  text-decoration: none;
  display: flex;
  justify-self: flex-end;
  letter-spacing: 0.5px;
`
const HighlightTextMobile = styled(HighlightText)`
  position: absolute;
  top: ${({ theme }) => theme.space[3]};
  right: ${({ theme }) => theme.space[3]};
`

const BtnWrapperSmall = styled.div`
  padding-top: ${({ theme }) => theme.space[1]};
  button,
  a {
    max-width: 220px;
  }
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledText = styled.p`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[1]};
  margin-top: ${({ theme }) => theme.space[7]};
`

const StyledSectionHeadingMT = styled(StyledParagraph)`
  margin-top: ${({ theme }) => theme.space[2]};
`
const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  transition: ${({ theme }) => theme.transitions.all};
  span {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
  }
`

const ArrowIcon = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.space[4]};
  right: 0;
  transform: rotate(-90deg);
`

const FormCardMobile: React.FC<IProps> = ({ rfId, pnq, pbq, addForm }) => {
  return (
    <>
      <StyledSectionHeading>PBI-formulär</StyledSectionHeading>

      {pbq.length > 0 ? (
        <>
          {pbq
            .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
            .map(({ created_at, score, rfId, scheduledFor }, index) => (
              <Fragment key={index}>
                {score && score !== null ? (
                  <Card>
                    <StyledLink to={`${ANSWEREDFORM_ID.getPathWithId(rfId)}`}>
                      <ContentContainer>
                        <StyledParagraphBold>Formulär</StyledParagraphBold>
                        <StyledText>PBQ, Behandlingens nytta</StyledText>
                        <StyledParagraphBold>Datum</StyledParagraphBold>
                        <StyledText>
                          {moment(created_at).format('YYYY-MM-DD')}
                        </StyledText>
                        <ArrowIcon>
                          <ArrowDown />
                        </ArrowIcon>
                      </ContentContainer>
                    </StyledLink>
                  </Card>
                ) : (
                  <Card>
                    <StyledLink to={`${NEWFORM_ID.getPathWithId(rfId)}`}>
                      <ContentContainer>
                        <StyledParagraphBold>Formulär</StyledParagraphBold>
                        <StyledText>PBQ, Behandlingens nytta</StyledText>
                        <StyledParagraphBold>Datum</StyledParagraphBold>
                        <StyledText>
                          {moment(created_at).format('YYYY-MM-DD')}
                        </StyledText>
                        {scheduledFor ? (
                          <HighlightTextMobile>
                            {scheduledFor.scheduledFor}
                          </HighlightTextMobile>
                        ) : (
                          <HighlightTextMobile>Nytt</HighlightTextMobile>
                        )}
                        <ArrowIcon>
                          <ArrowDown />
                        </ArrowIcon>
                      </ContentContainer>
                    </StyledLink>
                  </Card>
                )}
              </Fragment>
            ))}
        </>
      ) : null}

      {pnq ? (
        <>
          {pnq.score === null ? (
            <Card>
              <StyledLink to={`${NEWFORM_ID.getPathWithId(rfId)}`}>
                <ContentContainer>
                  <StyledParagraphBold>Formulär</StyledParagraphBold>
                  <StyledText>PNQ, Behandlingsmålens betydelse</StyledText>
                  <StyledParagraphBold>Datum</StyledParagraphBold>
                  <StyledText>
                    {moment(pnq.created_at).format('YYYY-MM-DD')}
                  </StyledText>
                  <HighlightTextMobile>nytt</HighlightTextMobile>
                  <ArrowIcon>
                    <ArrowDown />
                  </ArrowIcon>
                </ContentContainer>
              </StyledLink>
            </Card>
          ) : (
            <Card>
              <StyledLink to={`${ANSWEREDFORM_ID.getPathWithId(rfId)}`}>
                <ContentContainer>
                  <StyledParagraphBold>Formulär</StyledParagraphBold>
                  <StyledText>PNQ, Behandlingsmålens betydelse</StyledText>
                  <StyledParagraphBold>Datum</StyledParagraphBold>
                  <StyledText>
                    {moment(pnq.created_at).format('YYYY-MM-DD')}
                  </StyledText>
                  <ArrowIcon>
                    <ArrowDown />
                  </ArrowIcon>
                </ContentContainer>
              </StyledLink>
            </Card>
          )}
        </>
      ) : null}

      {pnq && pnq.score !== null ? (
        <>
          <StyledSectionHeadingMT>
            Vill du schemalägga nytt PBQ-formulär?
          </StyledSectionHeadingMT>
          <BtnWrapperSmall>
            <BtnDefault small onClick={() => addForm(rfId)}>
              Nytt PBQ-formulär
            </BtnDefault>
          </BtnWrapperSmall>
        </>
      ) : null}
    </>
  )
}
export default FormCardMobile
