import axios from 'axios'

//const BASE_URL = 'https://inrikta-strapi.herokuapp.com'
const BASE_URL = 'https://api.behandlingsrespons.se'

/**  LOGIN  **/
export const getUser = async () => {
  const apiClient = axios.create({
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.post('/login')
    return res.data.url
  } catch (err) {
    throw new Error(err)
  }
}

/**  LOGOUT  **/
export const logoutUser = async () => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get('/logout')
    return res
  } catch (err) {
    throw new Error(err)
  }
}

/**  STATUSCHECK  **/
export const checkStatus = async () => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get('/checkStatus')
    return res.data.url
  } catch (err) {
    throw new Error(err)
  }
}

/**  AUTH CHECK  **/
export const authCheck = async () => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get('/authCheck')
    return res.data
  } catch (err) {
    throw new Error(err)
  }
}

/**  ADD PNQ OE PBQ FORM  **/
export const sendFormInvite = async (
  patientSSID,
  formID,
  firstRelFormID,
  email,
  scheduledFor
) => {
  const body = JSON.stringify({
    patientSSID,
    formID,
    firstRelFormID,
    email,
    scheduledFor,
  })
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.post('forms', body)
    return res
  } catch (err) {
    throw new Error(err)
  }
}

/**  GET QUESTION CATEGORIES  **/
export const getCategories = async () => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get('/question-categories')
    return res
  } catch (err) {
    throw new Error(err)
  }
}

/**  GET FORM  **/
export const getPatientData = async () => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get('/forms/patient')
    return res
  } catch (err) {
    throw new Error(err)
  }
}

/**  GET FORMS  **/
export const getPatientForm = async rfId => {
  const apiClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.get(`/forms/patient/${rfId}`)
    return res
  } catch (err) {
    throw new Error(err)
  }
}

/**  POST FORM  **/
export const postForm = async ({ rfId, treatment, answers }) => {
  const body = JSON.stringify({
    rfId: rfId,
    answers: answers,
    treatment: treatment,
  })
  const apiClient = axios.create({
    baseURL: BASE_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  try {
    const res = await apiClient.post('forms/answer', body, {
      withCredentials: true,
    })
    return res
  } catch (err) {
    throw new Error(err)
  }
}
