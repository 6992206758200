import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { StyledParagraphBold, StyledParagraphSmall } from '../styles/typography'

export interface IRelation {
  created_at: string
  rfId: number
  score: number
}

export interface IQuestion {
  desc: string
  id: any
  question_category: number
  title: string
}

export interface IScore {
  created_at: string
  score: number
  categoryScores: Array<ICategoryScores>
}

export interface ICategoryScores {
  category_id: number
  score: number
}

export interface IForm {
  created_at: string
  desc: string
  descriptionPbq: string
  descriptionPnq: string
  id: number
  mainQuestion: string | null
  mainQuestionPbq: string
  mainQuestionPnq: string
  title: string
  titlePbq: string
  titlePnq: string
  questions: Array<IQuestion>
}

export interface IAnsweredForm {
  data: {
    answers: any
    answersExist: boolean
    form: IForm
    isPNQ: boolean
    patientName: string
    relation: Array<IRelation>
    scores: IScore
  }
}

export interface IProps {
  sectionHeight: number
}

const Card = styled.section<IProps>`
  background: ${({ theme }) => theme.primary};
  position: relative;
  overflow-y: scroll;
  border-radius: 0 0 8px 8px;
  height: ${props => props.sectionHeight}px;
  min-height: 500px;
  max-height: 700px;
  margin-bottom: ${({ theme }) => theme.space[6]};
  box-shadow: ${({ theme }) => theme.boxShadow};
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 10px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.primary};
    background-color: rgba(0, 0, 0, 0.2);
  }
  @media print {
    background: none;
    position: relative;
    overflow-y: unset;
    border-radius: 0 0 0 0;
    height: 700px;
    min-height: unset;
    max-height: unset;
    margin-bottom: ${({ theme }) => theme.space[6]};
    box-shadow: none;
  }
`

const Grid2ColHeader = styled.div`
  display: grid;
  grid-template-columns: 66% repeat(6, minmax(30px, 1fr));
  grid-gap: 6px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[3]};
  padding-left: 0;
  margin-top: 48px;
  @media print {
    page-break-before: always;
  }
`

const Grid2Col = styled.div`
  display: grid;
  grid-template-columns: 66% repeat(6, minmax(30px, 1fr));
  grid-gap: 0px;
  width: 100%;
  padding: ${({ theme }) => theme.space[1]} 0;
  padding-left: ${({ theme }) => theme.space[3]};
  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.backgroundlight};
  }
  @media print {
    grid-template-columns: 66% repeat(6, minmax(10px, 1fr));
    padding: 0;
  }
`

const StyledLine = styled.hr`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.highlight};
  height: 1px;
  color: ${({ theme }) => theme.highlight};
  background-color: ${({ theme }) => theme.highlight};
  margin-bottom: 0;
`

const StyledLabel = styled.label`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
  transform: rotate(-60deg);
  white-space: nowrap;
  padding-top: 24px;
`

const StyledLabelItalic = styled(StyledLabel)`
  font-style: italic;
`

const Wrapper = styled.div`
  position: relative;
  align-self: center;
  width: 20px;
  height: 20px;
`

const RadioButton = styled.input`
  z-index: 1;
  cursor: default;
  &[type='radio'] {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
  }
  &:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
  }
  -webkit-print-color-adjust: exact !important;
`

const RadioButtonOrange = styled(RadioButton)`
  &:checked:before {
    background: ${({ theme }) => theme.pop};
  }
  &:before {
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.pop};
  }
`

const StyledMainQuestion = styled(StyledParagraphBold)`
  margin-top: 30px;
`

const DesktopAnsweredForm: React.FC<IAnsweredForm> = ({ data }) => {
  const [height, setHeight] = useState(window.innerHeight)
  const { register, setValue } = useForm()

  const updateHeight = () => {
    setHeight(window.innerHeight - 560)
  }
  useEffect(() => {
    updateHeight()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  })

  useEffect(() => {
    if (data.answers !== null) {
      const timer = setTimeout(() => {
        Object.entries(data.answers).map(([key, value]) =>
          setValue(key, value.toString())
        )
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [data.answers])

  return (
    <>
      {data.form && (
        <>
          <Grid2ColHeader>
            {data.isPNQ ? (
              <StyledMainQuestion>
                {data.form.mainQuestionPnq}
              </StyledMainQuestion>
            ) : (
              <StyledMainQuestion>
                {data.form.mainQuestionPbq}
              </StyledMainQuestion>
            )}
            <StyledLabel htmlFor="1">Inte alls</StyledLabel>
            <StyledLabel htmlFor="2">Lite</StyledLabel>
            <StyledLabel htmlFor="3">Till viss del</StyledLabel>
            <StyledLabel htmlFor="4">Ganska mycket</StyledLabel>
            <StyledLabel htmlFor="5">Mycket</StyledLabel>
            <StyledLabelItalic htmlFor="6">Ej tillämpligt</StyledLabelItalic>
          </Grid2ColHeader>
          <StyledLine />

          <form>
            <Card sectionHeight={height}>
              {data.form.questions.map(({ id, title }) => (
                <Grid2Col key={id}>
                  <StyledParagraphSmall>{title}</StyledParagraphSmall>
                  <Wrapper>
                    <fieldset disabled>
                      <RadioButton
                        readOnly
                        name={id}
                        type="radio"
                        value="0"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>

                  <Wrapper>
                    <fieldset disabled>
                      <RadioButton
                        readOnly
                        name={id}
                        type="radio"
                        value="1"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>

                  <Wrapper>
                    <fieldset disabled>
                      <RadioButton
                        readOnly
                        name={id}
                        type="radio"
                        value="2"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>

                  <Wrapper>
                    <fieldset disabled>
                      <RadioButton
                        readOnly
                        name={id}
                        type="radio"
                        value="3"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>

                  <Wrapper>
                    <fieldset disabled>
                      <RadioButton
                        readOnly
                        name={id}
                        type="radio"
                        value="4"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>

                  <Wrapper>
                    <fieldset disabled>
                      <RadioButtonOrange
                        readOnly
                        name={id}
                        type="radio"
                        value="5"
                        ref={register({ required: true })}
                      />
                    </fieldset>
                  </Wrapper>
                </Grid2Col>
              ))}
            </Card>
          </form>
        </>
      )}
    </>
  )
}

export default DesktopAnsweredForm
