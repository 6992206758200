import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BtnDefault from '../components/common/BtnDefault'
import IdleTimerContainer from '../components/IdleTimer/IdleTimerContainer'
import { StyledH1, StyledParagraph } from '../styles/typography'

const Card = styled.section`
  position: absolute;
  top: calc(50% - 60px);
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  padding: ${({ theme }) => theme.space[3]};
`

const BtnContainer = styled.div`
  height: 76px;
  margin-top: ${({ theme }) => theme.space[3]};
  a {
    max-width: 150px;
  }
`

const Layout = styled.section`
  width: 100vw;
  height: 100vh;
`

const NoMatch: React.FC = () => {
  const history = useHistory()
  return (
    <Layout>
      <IdleTimerContainer />
      <Card>
        <StyledH1>404 - Sidan hittades inte</StyledH1>
        <StyledParagraph>
          Sidan du söker verkar inte finnas. Kontrollera att du skirivt rätt och
          försök igen.
        </StyledParagraph>
        <BtnContainer>
          <BtnDefault onClick={() => history.goBack()}>Tillbaka</BtnDefault>
        </BtnContainer>
      </Card>
    </Layout>
  )
}

export default NoMatch
