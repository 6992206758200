import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  to?: string
  children: ReactNode
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  letter-spacing: 0.5px;
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  color: ${({ theme }) => theme.primary};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius.button};
  box-shadow: ${({ theme }) => theme.boxShadow};
  background: ${({ theme }) => theme.highlightdark};
  border: none;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  height: 38px;
  width: 116px;
  transition: ${({ theme }) => theme.transitions.all};
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  &:hover {
    opacity: 0.6;
  }
`

const BtnLogout: React.FC<IProps> = ({ onClick, children, type }) => {
  return (
    <StyledButton onClick={onClick} type={type}>
      {children}
    </StyledButton>
  )
}

export default BtnLogout
