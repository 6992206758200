import React, { useEffect, useState } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  LOGIN,
  NEWFORM_ID,
  ANSWEREDFORM_ID,
  HOME_PATIENT,
  ERROR,
  NOTFOUND,
} from '../../utils/urlRoutes'
import { useAuthDataContext } from '../../context/AuthContext'
import Login from '../../pages/Login'
import HomePatient from '../../pages/HomePatient'
import NewForms from '../../pages/NewForms'
import AnsweredForm from '../../pages/AnsweredForm'
import Header from '../navigation/Header'
import Footer from '../footer/Footer'
import Error from '../../pages/Error'
import NoMatch from '../../pages/404'
import CircleLoader from '../CircleLoader'
import LayoutHomePage from '../LayoutHomePage'
import { authCheck } from '../../services/Api'

// ** Gated patient route ** //
const PrivatePatientRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  const { isPatient } = useAuthDataContext()
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && isPatient ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

const Routes = () => {
  const history = useHistory()
  const { authData, addAuth, setIsPatient } = useAuthDataContext()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  const authenticated = async () => {
    let isValidRequest = false
    try {
      const response = await authCheck()
      addAuth(response)
      setIsPatient(response.isPatient)
      if (response.isPatient) {
        isValidRequest = true
        return isValidRequest
      }
    } catch (err) {
      history.push(HOME_PATIENT.path)
    }
  }

  useEffect(() => {
    if (location.pathname === LOGIN.path || location.pathname === '/') {
      setLoading(false)
    }
    if (!authData && location.pathname !== LOGIN.path) {
      async function checkAuth() {
        try {
          const isAuth = await authenticated()
          setIsAuthenticated(isAuth)
          setLoading(false)
        } catch (err) {
          if (err.message === 'Error: Request failed with status code 401') {
            setLoading(false)
            history.push(LOGIN.path)
          }
        }
      }
      checkAuth()
    } else {
      return
    }
  }, [])

  if (loading) {
    return (
      <>
        <Header />
        <LayoutHomePage>
          <CircleLoader />
        </LayoutHomePage>
        <Footer />
      </>
    )
  }

  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path={LOGIN.path} component={Login} />

        <PrivatePatientRoute
          exact
          path={HOME_PATIENT.path}
          component={HomePatient}
          isAuthenticated={isAuthenticated}
        />

        <PrivatePatientRoute
          path={NEWFORM_ID.path}
          component={NewForms}
          isAuthenticated={isAuthenticated}
        />
        <PrivatePatientRoute
          path={ANSWEREDFORM_ID.path}
          component={AnsweredForm}
          isAuthenticated={isAuthenticated}
        />

        <Route path={ERROR.path} component={Error} />
        <Route path={NOTFOUND.path} component={NoMatch} />
        <Redirect to={NOTFOUND.path} />
      </Switch>
      <Footer />
    </>
  )
}

export default Routes
