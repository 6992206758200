import React from 'react'
import { useLocation } from 'react-router-dom'
import FooterDefault from './FooterDefault'
import FooterLogin from './FooterLogin'

const Footer: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <>
      {pathname === '/login' ||
      pathname === '/' ||
      pathname === '/not-found' ? (
        <FooterLogin />
      ) : (
        <FooterDefault />
      )}
    </>
  )
}

export default Footer
