import React from 'react'
import styled from 'styled-components'

interface IArrowDownProps {
  onClick?: any
}

// interface IStyleProps {
//   isActive?: boolean
// }

const StyledIcon = styled.svg`
  display: block;
  height: 8px;
  transition: ${({ theme }) => theme.transitions.all};
  &:hover {
    transform: scale(1.2);
  }
  path {
    fill: ${({ theme }) => theme.highlight};
  }
`

const ArrowDown: React.FC<IArrowDownProps> = ({ onClick }) => {
  return (
    <StyledIcon
      onClick={onClick}
      width="60"
      height="36"
      viewBox="0 0 60 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 5.65685L5.65685 0L35.3553 29.6985L29.6985 35.3553L0 5.65685Z" />
      <path d="M29.6985 35.3553L24.0414 29.6985L53.7399 0L59.3967 5.65685L29.6985 35.3553Z" />
    </StyledIcon>
  )
}

export default ArrowDown
