import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { HOME_PATIENT } from '../utils/urlRoutes'
import { getPatientData } from '../services/Api'
import styled from 'styled-components'
import { StyledH1, StyledParagraph } from '../styles/typography'
import IdleTimerContainer from '../components/IdleTimer/IdleTimerContainer'
import LayoutHomePage from '../components/LayoutHomePage'
import BtnDefault from '../components/common/BtnDefault'
import CircleLoader from '../components/CircleLoader'
import Media from 'react-media'
import FormCardDesktop from '../components/FormCardDesktop'
import FormCardMobile from '../components/FormCardMobile'
import AddPbqModal from '../components/AddPbqModal'
import AddPnqModal from '../components/AddPnqModal'
import { useHistory } from 'react-router-dom'

const StyledHeading = styled(StyledH1)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[4]};
`

const BtnWrapperSmall = styled.div`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  button,
  a {
    max-width: 220px;
  }
`
const StyledSectionHeadingMT = styled(StyledParagraph)`
  margin-top: ${({ theme }) => theme.space[4]};
  padding-bottom: ${({ theme }) => theme.space[0]};
`
const StyledList = styled.ul`
  max-width: 860px;
  margin-left: ${({ theme }) => theme.space[3]};
  li {
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: 1.5;
    ol {
      margin-left: ${({ theme }) => theme.space[3]};
    }
  }
`

const HomePatient: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showPnq, setShowPnq] = useState(false)
  const [formRFID, setFormRFID] = useState(null)
  const [patientData, setPateintData] = useState(null)
  const [patientForms, setPatientForms] = useState(null)

  const fetchPatientForms = async () => {
    setLoading(true)
    try {
      const response = await getPatientData()
      setPateintData(response.data)
      setPatientForms(response.data.forms)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err.message === 'Error: Request failed with status code 400') {
        setLoading(false)
        addToast('Felaktig begäran', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else if (err.message === 'Error: Request failed with status code 401') {
        setLoading(false)
        addToast('Ej autentiserad', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setLoading(false)
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      history.push(HOME_PATIENT.path)
    }
  }

  useEffect(() => {
    fetchPatientForms()
  }, [])

  // *** add new PNQ
  const addPnqForm = async rfId => {
    setShowPnq(true)
    setFormRFID(rfId)
  }

  // *** add new follow up form (PBQ)
  const addForm = async rfId => {
    setShow(true)
    setFormRFID(rfId)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutHomePage>
      {loading ? (
        <CircleLoader />
      ) : patientForms ? (
        <>
          <IdleTimerContainer />
          <AddPbqModal
            show={show}
            setShow={setShow}
            rfId={formRFID}
            name={patientData.patientName}
            ssid={patientData.patientSSID}
          />
          <AddPnqModal
            showPnq={showPnq}
            setShowPnq={setShowPnq}
            rfId={formRFID}
            name={patientData.patientName}
            ssid={patientData.patientSSID}
          />
          <StyledHeading>Så här fungerar det</StyledHeading>
          <StyledList>
            <li>
              Du kommer nu fylla i två olika formulär, ett i taget:
              <ol>
                <li>
                  Det första kallas PNQ (kort för Patient Needs Questionnaire)
                  och utvärderar dina personliga behov. Här kommer du att få
                  svara på vilka aspekter av din sjukdom som är viktigast för
                  dig att få hjälp med.
                </li>
                <li>
                  I det andra formuläret, PBQ (Patient Benefits Questionnaire),
                  får du ange inom vilka områden din nuvarande behandling har
                  hjälpt dig, för att få en bild av hur väl detta stämmer
                  överens med dina individuella behandlingsmål.
                </li>
                <li>
                  Du behöver inte fylla i ett nytt PNQ-formulär så länge inte
                  dina behov/behandlingsmål ändras. Under din behandling kan du
                  regelbundet fylla i PBQ för att följa förändring i
                  behandlingsnytta över tid. Du bestämmer själv, eller i samråd
                  med din läkare, hur ofta det är lämpligt att göra detta.
                </li>
              </ol>
            </li>
            <li>
              När dessa två är ifyllda kommer du att få ett värde som visar hur
              stor nytta du har av din nuvarande behandling.
            </li>
            <li>
              När du i framtiden fyller i fler formulär kommer du att kunna
              följa förändringen över tid.
            </li>
          </StyledList>
          <StyledParagraph></StyledParagraph>
          <StyledSectionHeadingMT>
            Vill du starta en ny utvärdering?
          </StyledSectionHeadingMT>
          <BtnWrapperSmall>
            <BtnDefault small onClick={() => addPnqForm(null)}>
              Nytt PNQ-formulär
            </BtnDefault>
          </BtnWrapperSmall>
          <Media query="(max-width: 767px)">
            {matches =>
              matches ? (
                <>
                  {patientForms && patientForms.length > 0
                    ? patientForms.map(({ pnq, pbq, rfId }) => {
                        return (
                          <FormCardMobile
                            key={rfId}
                            rfId={rfId}
                            pnq={pnq}
                            pbq={pbq}
                            addForm={addForm}
                          />
                        )
                      })
                    : null}
                </>
              ) : (
                <>
                  {patientForms && patientForms.length > 0
                    ? patientForms.map(({ pnq, pbq, rfId }) => {
                        return (
                          <FormCardDesktop
                            key={rfId}
                            rfId={rfId}
                            pnq={pnq}
                            pbq={pbq}
                            addForm={addForm}
                          />
                        )
                      })
                    : null}
                </>
              )
            }
          </Media>
        </>
      ) : null}
    </LayoutHomePage>
  )
}

export default HomePatient
