import styled from 'styled-components'

export const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: 400;
  ${({ theme }) => theme.xs`
      font-size: ${({ theme }) => theme.fontSizes[6]};
  `}
`

export const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: 300;
`
export const StyledH3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: 300;
`

export const StyledH4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 400;
`

export const StyledParagraph = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
  max-width: 860px;
`
export const StyledParagraphSmall = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
`

export const StyledParagraphBold = styled.p`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
`
