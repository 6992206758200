import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.section`
  background: ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[3]};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin: ${({ theme }) => theme.space[3]} 0;
`
export interface IProps {
  children: ReactNode
}

const Card: React.FC<IProps> = ({ children }) => {
  return <Container>{children}</Container>
}
export default Card
