import { css } from 'styled-components'
import { getParseTreeNode } from 'typescript'

export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1024px',
  xl: '1440px',
  xlarge: 'Infinity',
}

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) =>
    css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `
  return accumulator
}, {})

const theme = {
  primary: '#fff',
  secondary: '#000',
  text: '#212121',
  link: '#4c74b9',
  highlight: '#039fbe',
  highlightdark: '#066679',
  highlightlight: '#6DD6EB',
  backgroundlight: '#93d7e420',
  backgroundlighthover: '#93d7e460',
  pop: '#ffb016',
  border: '#dadce0',
  inputtext: '#888888',
  error: '#cc0000',
  background: '#fafafa',
  textGrey: '#949699',
  fontSizes: [
    '12px',
    '14px',
    '16px',
    '18px',
    '24px',
    '32px',
    '38px',
    '54px',
    '72px',
  ],
  fontWeights: [300, 400, 500, 600, 700],
  lineHeights: {
    body: '22px',
    heading: '30px',
    subHeading: '26px',
  },
  borderRadius: {
    form: '8px',
    input: '25px',
    button: '25px',
    card: '5px',
  },
  transitions: {
    all: 'all 0.25s cubic-bezier(0, 0, 0.2, 1)',
  },
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.07)',
  space: [
    '4px',
    '8px',
    '16px',
    '24px',
    '32px',
    '40px',
    '48px',
    '64px',
    '96px',
    '110px',
  ],
  ...media,
}
export default theme
