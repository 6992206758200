import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface IProps {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  to?: string
  children: ReactNode
  small?: boolean
}

export interface IStyleProps {
  smallBtn?: boolean
}

const StyledButton = styled.button<IStyleProps>`
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding-bottom: ${props => (props.smallBtn ? '1px' : '0')};
  box-shadow: ${({ theme }) => theme.boxShadow};
  font-size: ${props =>
    props.smallBtn ? props.theme.fontSizes[2] : props.theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  background: ${props =>
    props.smallBtn ? props.theme.highlightlight : props.theme.highlight};
  color: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  border-style: solid;
  border-width: 2px;
  border-color: ${props =>
    props.smallBtn ? props.theme.highlightlight : props.theme.highlight};
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  height: ${props => (props.smallBtn ? '38px' : '46px')};
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: ${({ theme }) => theme.transitions.all};
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  &:hover {
    opacity: 0.6;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  color: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  background: ${({ theme }) => theme.highlight};
  border: 2px solid ${({ theme }) => theme.highlight};
  box-shadow: ${({ theme }) => theme.boxShadow};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  height: 46px;
  width: 100%;
  display: flex;
  text-align: center;
  transition: ${({ theme }) => theme.transitions.all};
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  &:hover {
    opacity: 0.6;
  }
`

const BtnDefault: React.FC<IProps> = ({
  onClick,
  children,
  type,
  to,
  small,
}) => {
  return (
    <>
      {to === undefined ? (
        <StyledButton
          onClick={onClick}
          type={type}
          smallBtn={small ? true : false}
        >
          {children}
        </StyledButton>
      ) : (
        <StyledLink to={to}>{children}</StyledLink>
      )}
    </>
  )
}

export default BtnDefault
