import React from 'react'
import AuthDataProvider from './context/AuthContext'
import { ToastProvider } from 'react-toast-notifications'
import Routes from './components/router/Router'

const App = () => {
  return (
    <AuthDataProvider>
      <ToastProvider autoDismissTimeout={2000}>
        <Routes />
      </ToastProvider>
    </AuthDataProvider>
  )
}

export default App
