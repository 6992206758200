import React, { useEffect, useState } from 'react'
import Media from 'react-media'
import { useToasts } from 'react-toast-notifications'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { getPatientForm } from '../services/Api'
import { HOME_PATIENT } from '../utils/urlRoutes'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  StyledH1,
  StyledH3,
  StyledParagraph,
  StyledParagraphBold,
} from '../styles/typography'
import LayoutHomePage from '../components/LayoutHomePage'
import IdleTimerContainer from '../components/IdleTimer/IdleTimerContainer'
import DesktopAnsweredForm from '../components/DesktopAnsweredForm'
import MobileAnsweredForm from '../components/MobileAnsweredForm'
import BtnDefault from '../components/common/BtnDefault'
import CircleLoader from '../components/CircleLoader'
import ScoreTable from '../components/ScoreTable'
import Chart from '../components/Chart'
import moment from 'moment'

interface ParamTypes {
  id: string
}

const StyledHeading = styled(StyledH1)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[4]};
`

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin-right: ${({ theme }) => theme.space[0]};
  }
  button {
    margin-left: ${({ theme }) => theme.space[0]};
  }

  button,
  a {
    max-width: 200px;
  }
  @media print {
    display: none;
  }
`

const StyledBackLink = styled(Link)`
  padding-left: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-decoration: none;
  position: relative;
  transition: ${({ theme }) => theme.transitions.all};
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.highlight};
  }
  &:before {
    border-color: ${({ theme }) => theme.highlight};
    border-style: solid;
    border-width: 0 0 3px 3px;
    content: '';
    display: block;
    height: 6px;
    left: 0px;
    position: absolute;
    width: 6px;
    margin-top: -1px;
    top: 6px;
    transform: rotate(45deg);
    transition: ${({ theme }) => theme.transitions.all};
  }
  @media print {
    display: none;
  }
`
const StyledMainQuestion = styled(StyledParagraphBold)`
  ${({ theme }) => theme.sm`
    margin-top: 30px;
  `}
`

const MobileMainQuestion = styled.div`
  display: block;
  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  ${({ theme }) => theme.sm`
    display: none;
  `}
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[5]};
`

const CircleBlue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.highlightlight};
  background: ${({ theme }) => theme.highlightlight};
  span {
    color: ${({ theme }) => theme.primary};
  }
`

const CircleOrange = styled(CircleBlue)`
  background: ${({ theme }) => theme.pop};
  border: 1px solid ${({ theme }) => theme.pop};
`

const Flex = styled.div`
  display: flex;
`

const StyledLabel = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
  padding-left: ${({ theme }) => theme.space[1]};
`
const StyledLabelItalic = styled(StyledLabel)`
  font-style: italic;
`
const Card = styled.section`
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[3]} 0;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin: ${({ theme }) => theme.space[3]} 0;
  @media print {
    page-break-before: always;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
  }
`

const CardBg = styled.section`
  background: linear-gradient(
    180deg,
    rgba(63, 198, 0, 0.6) 0%,
    rgba(227, 230, 105, 0.6) 47.94%,
    rgba(255, 187, 84, 0.6) 74.6%,
    rgba(251, 137, 112, 0.6) 90.17%
  );
  padding: ${({ theme }) => theme.space[3]};
  position: relative;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin: ${({ theme }) => theme.space[3]} 0;
  @media print {
    page-break-before: always;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
  }
`
const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: 0 ${({ theme }) => theme.space[3]};
`
const StyledTextLeft = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  margin: 0 ${({ theme }) => theme.space[3]};
`

const StyledTextLeftBold = styled(StyledTextLeft)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[1]};
`

const StyledTextBold = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin-top: ${({ theme }) => theme.space[3]};
`

const MobileView = ({ data }) => (
  <>
    {data.isPNQ ? (
      <>
        <StyledHeading>{data.form.titlePnq}</StyledHeading>
        <StyledParagraph>{data.form.descriptionPnq}</StyledParagraph>
      </>
    ) : (
      <>
        <StyledHeading>{data.form.titlePbq}</StyledHeading>
        <StyledParagraph>{data.form.descriptionPbq}</StyledParagraph>
      </>
    )}
    {data.treatment && data.treatment[0].name ? (
      <>
        <StyledTextBold>Behandling:</StyledTextBold>
        {data.treatment[0].name && (
          <StyledParagraph>
            Behandling: {data.treatment[0].name}
          </StyledParagraph>
        )}
        {data.treatment[0].startDate && (
          <StyledParagraph>
            Startdatum: {data.treatment[0].startDate}
          </StyledParagraph>
        )}
      </>
    ) : null}
    <Container>
      <Flex>
        <CircleBlue>
          <span>0</span>
        </CircleBlue>
        <StyledLabel>Inte alls</StyledLabel>
      </Flex>
      <Flex>
        <CircleBlue>
          <span>1</span>
        </CircleBlue>
        <StyledLabel>Lite</StyledLabel>
      </Flex>
      <Flex>
        <CircleBlue>
          <span>2</span>
        </CircleBlue>
        <StyledLabel>Till viss del</StyledLabel>
      </Flex>
      <Flex>
        <CircleBlue>
          <span>3</span>
        </CircleBlue>
        <StyledLabel>Ganska mycket</StyledLabel>
      </Flex>
      <Flex>
        <CircleBlue>
          <span>4</span>
        </CircleBlue>
        <StyledLabel>Mycket</StyledLabel>
      </Flex>
      <Flex>
        <CircleOrange>
          <span>5</span>
        </CircleOrange>
        <StyledLabelItalic>Ej tillämpligt</StyledLabelItalic>
      </Flex>
    </Container>
    {data.isPNQ ? (
      <MobileMainQuestion>
        <StyledMainQuestion>{data.form.mainQuestionPnq}</StyledMainQuestion>
      </MobileMainQuestion>
    ) : (
      <MobileMainQuestion>
        <StyledMainQuestion>{data.form.mainQuestionPbq}</StyledMainQuestion>
      </MobileMainQuestion>
    )}
    <MobileAnsweredForm data={data} />
  </>
)

const DesktopView = ({ data }) => (
  <>
    {data.isPNQ ? (
      <>
        <StyledHeading>{data.form.titlePnq}</StyledHeading>
        <StyledParagraph>{data.form.descriptionPnq}</StyledParagraph>
      </>
    ) : (
      <>
        <StyledHeading>{data.form.titlePbq}</StyledHeading>
        <StyledParagraph>{data.form.descriptionPbq}</StyledParagraph>
      </>
    )}
    {data.treatment && data.treatment[0].name ? (
      <>
        <StyledTextBold>Behandling:</StyledTextBold>
        {data.treatment[0].name && (
          <StyledParagraph>
            Behandling: {data.treatment[0].name}
          </StyledParagraph>
        )}
        {data.treatment[0].startDate && (
          <StyledParagraph>
            Startdatum: {data.treatment[0].startDate}
          </StyledParagraph>
        )}
      </>
    ) : null}
    <DesktopAnsweredForm data={data} />
  </>
)

const AnsweredForm: React.FC = () => {
  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [newArr, setNewArr] = useState([])

  const fetchForm = async id => {
    setLoading(true)
    try {
      const response = await getPatientForm(id)
      if (!response.data.answersExist) {
        addToast('Felaktig begäran.', {
          appearance: 'error',
          autoDismiss: true,
        })
        history.push(HOME_PATIENT.path)
      }
      if (response.data.answersExist) {
        setData(response.data)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      if (err.message === 'Error: Request failed with status code 400') {
        setLoading(false)
        addToast('Felaktig begäran', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else if (err.message === 'Error: Request failed with status code 401') {
        setLoading(false)
        addToast('Ej autentiserad', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setLoading(false)
        addToast('Något gick fel. Försök igen.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      history.push(HOME_PATIENT.path)
    }
  }

  useEffect(() => {
    if (data) {
      const getKey = data => {
        const arr = [],
          obj = Object.keys(data)
        for (var x in obj) {
          if (data[obj[x]] === 3 || data[obj[x]] === 4) {
            arr.push(obj[x])
          }
        }
        return setNewArr(arr)
      }
      getKey(data.answers)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      let productList = data.form.questions
      let theDatabaseList = newArr
      var objectsFound = []
      for (var i = 0; i < Object.keys(productList).length; i++) {
        for (var j = 0; j < Object.keys(theDatabaseList).length; j++) {
          if (productList[i].id === Number(theDatabaseList[j])) {
            objectsFound.push(productList[i])
          }
        }
      }
    }
    setSelectedQuestions(objectsFound)
  }, [newArr])

  useEffect(() => {
    fetchForm(id)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <LayoutHomePage>
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <IdleTimerContainer />
          <StyledBackLink to={HOME_PATIENT.path}>Tillbaka</StyledBackLink>
          {data && (
            <>
              <Media query="(max-width: 607px; ) ">
                {matches =>
                  matches ? (
                    <MobileView data={data} />
                  ) : (
                    <DesktopView data={data} />
                  )
                }
              </Media>
              {!data.isPNQ ? (
                <>
                  {data.scores && (
                    <ScoreTable
                      AVGScore={data.scores.score}
                      CATScores={data.scores.categoryScores}
                    />
                  )}
                  {selectedQuestions && selectedQuestions.length > 0 ? (
                    <Card>
                      <StyledSectionHeading>
                        Behandlingen har hjälpt mig mest med ...
                      </StyledSectionHeading>
                      {selectedQuestions.map(({ title }) => (
                        <StyledTextLeftBold key={title}>
                          {title}
                        </StyledTextLeftBold>
                      ))}
                    </Card>
                  ) : null}
                  <CardBg>
                    <Chart data={data.relation} />
                  </CardBg>
                </>
              ) : (
                <>
                  {selectedQuestions && selectedQuestions.length > 0 ? (
                    <Card>
                      <StyledSectionHeading>
                        Mina viktigaste behandlingsmål
                      </StyledSectionHeading>
                      {selectedQuestions.map(({ title }) => (
                        <StyledTextLeftBold key={title}>
                          {title}
                        </StyledTextLeftBold>
                      ))}
                    </Card>
                  ) : null}
                </>
              )}

              <BtnWrapper>
                <BtnDefault to={HOME_PATIENT.path}>Tillbaka</BtnDefault>
                <BtnDefault onClick={() => window.print()}>Skriv ut</BtnDefault>
              </BtnWrapper>
            </>
          )}
        </>
      )}
    </LayoutHomePage>
  )
}

export default AnsweredForm
