import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { StyledParagraphSmall } from '../styles/typography'
export interface IRelation {
  created_at: string
  rfId: number
  score: number
}

export interface IQuestion {
  desc: string
  id: any
  question_category: number
  title: string
}

export interface IScore {
  created_at: string
  score: number
  categoryScores: Array<ICategoryScores>
}

export interface ICategoryScores {
  category_id: number
  score: number
}

export interface IForm {
  created_at: string
  desc: string
  descriptionPbq: string
  descriptionPnq: string
  id: number
  mainQuestion: string | null
  mainQuestionPbq: string
  mainQuestionPnq: string
  title: string
  titlePbq: string
  titlePnq: string
  questions: Array<IQuestion>
}

export interface IAnsweredForm {
  data: {
    answers: any
    answersExist: boolean
    form: IForm
    isPNQ: boolean
    patientName: string
    relation: Array<IRelation>
    scores: IScore
  }
}

const Question = styled(StyledParagraphSmall)`
  font-weight: ${({ theme }) => theme.fontWeights[2]};
  margin: 0 ${({ theme }) => theme.space[3]};
`

const StyledCard = styled.section`
  background: ${({ theme }) => theme.primary};
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[4]};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.form};
  box-shadow: ${({ theme }) => theme.boxShadow};
  height: auto;
  margin-bottom: ${({ theme }) => theme.space[2]};
`

const Grid6Col = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2 20px);
  width: 100%;
  justify-items: center;
  padding: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[5]};
`

const StyledLabel = styled.label`
  display: none;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 1.5;
  text-align: center;
  width: 120px;
`
const StyledLabelItalic = styled(StyledLabel)`
  font-style: italic;
`

const Wrapper = styled.div`
  position: relative;
  span {
    color: ${({ theme }) => theme.primary};
    position: absolute;
    z-index: 1000;
    right: 4.5px;
    bottom: 1px;
    width: 100%;
    height: 100%;
    background: none;
  }
`
const RadioButton = styled.input`
  z-index: 1;
  cursor: default;
  &[type='radio']:checked + label {
    display: block;
    position: absolute;
    top: 30px;
    left: -60px;
  }
  &[type='radio'] {
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
  }
  &:checked:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.highlightlight};
    border-radius: 50%;
    left: -1px;
    top: -1px;
  }
`
const RadioButtonOrange = styled(RadioButton)`
  &:checked:before {
    background: ${({ theme }) => theme.pop};
  }
  &:before {
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.pop};
  }
`

const MobileAnsweredForm: React.FC<IAnsweredForm> = ({ data }) => {
  const { register, setValue } = useForm()

  useEffect(() => {
    if (data.answers !== null) {
      const timer = setTimeout(() => {
        Object.entries(data.answers).map(([key, value]) =>
          setValue(key, value.toString())
        )
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [data.answers])

  return (
    <>
      {data.form && (
        <>
          <form>
            {data.form.questions.map(({ id, title }) => (
              <>
                <StyledCard key={id}>
                  <Question>{title}</Question>
                  <Grid6Col>
                    <Wrapper>
                      <fieldset disabled>
                        <RadioButton
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="0"
                          ref={register({ required: true })}
                        />
                        <StyledLabel htmlFor="0">Inte alls</StyledLabel>
                      </fieldset>
                      <span>0</span>
                    </Wrapper>
                    <Wrapper>
                      <fieldset disabled>
                        <RadioButton
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="1"
                          ref={register({ required: true })}
                        />
                        <StyledLabel htmlFor="1">Lite</StyledLabel>
                      </fieldset>
                      <span>1</span>
                    </Wrapper>

                    <Wrapper>
                      <fieldset disabled>
                        <RadioButton
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="2"
                          ref={register({ required: true })}
                        />
                        <StyledLabel htmlFor="2">Till viss del</StyledLabel>
                      </fieldset>
                      <span>2</span>
                    </Wrapper>

                    <Wrapper>
                      <fieldset disabled>
                        <RadioButton
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="3"
                          ref={register({ required: true })}
                        />
                        <StyledLabel htmlFor="3">Ganska mycket</StyledLabel>
                      </fieldset>
                      <span>3</span>
                    </Wrapper>

                    <Wrapper>
                      <fieldset disabled>
                        <RadioButton
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="4"
                          ref={register({ required: true })}
                        />
                        <StyledLabel htmlFor="4">Mycket</StyledLabel>
                      </fieldset>
                      <span>4</span>
                    </Wrapper>

                    <Wrapper>
                      <fieldset disabled>
                        <RadioButtonOrange
                          readOnly
                          id={id}
                          name={id}
                          type="radio"
                          value="5"
                          ref={register({ required: true })}
                        />
                        <StyledLabelItalic htmlFor="5">
                          Ej tillämpligt
                        </StyledLabelItalic>
                      </fieldset>
                      <span>5</span>
                    </Wrapper>
                  </Grid6Col>
                </StyledCard>
              </>
            ))}
          </form>
        </>
      )}
    </>
  )
}

export default MobileAnsweredForm
