import React from 'react'
import BtnDefault from '../common/BtnDefault'
import CircleLoader from '../CircleLoader'
import styled from 'styled-components'
import { StyledH3, StyledParagraph } from '../../styles/typography'

const Background = styled.div`
  position: fixed;
  z-index: 998;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
  background-color: ${({ theme }) => theme.secondary};
`

const Card = styled.div`
  background: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.borderRadius.form};
  height: auto;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 90vw;
  max-width: 440px;
  opacity: 1;
`

const StyledSectionHeading = styled(StyledH3)`
  margin-bottom: ${({ theme }) => theme.space[2]};
`

const BtnWrapperSmall = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space[3]};
  ${({ theme }) => theme.sm`
    flex-direction: row;
  `}
  button {
    width: 100%;
    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.space[2]};
    }
    ${({ theme }) => theme.sm`
      &:first-of-type {
        margin-right: ${({ theme }) => theme.space[1]};
      }
      &:last-of-type {
        margin-left: ${({ theme }) => theme.space[1]};
      }
  `}
  }
`

const IdleTimerModal = ({ show, loading, stayActive, logout }) => {
  return (
    show && (
      <>
        <Background />

        <Card>
          <StyledSectionHeading>Automatisk utloggning</StyledSectionHeading>
          <StyledParagraph>
            Du har varit inaktiv ett tag. För din säkerhets skull loggas du ut
            om 30 sekunder. Vill du fortsätta vara inloggad?
          </StyledParagraph>

          <BtnWrapperSmall>
            {loading ? (
              <CircleLoader />
            ) : (
              <>
                <BtnDefault small onClick={() => stayActive()}>
                  Fortsätt
                </BtnDefault>
                <BtnDefault small onClick={() => logout()}>
                  Logga ut
                </BtnDefault>
              </>
            )}
          </BtnWrapperSmall>
        </Card>
      </>
    )
  )
}

export default IdleTimerModal
